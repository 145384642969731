(function($) {

	/* Lightbox
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	AOS.init();



	// hover functions

	$(function() {
		var item1 = '.cta-with-image-block .blocks .item1';
		var item1img = '.cta-with-image-block .images .image1';
		var item2 = '.cta-with-image-block .blocks .item2';
		var item2img = '.cta-with-image-block .images .image2';
		$(item1).mouseenter(function() {
	    $(item1img).addClass("active");
	  }).mouseleave(function () {
			// $(item1img).removeClass("active");
	  });
		$(item2).mouseenter(function() {
			$(item1img).removeClass("active");
 			$(item2img).addClass("active");
	  }).mouseleave(function () {
			$(item1img).addClass("active");
			$(item2img).removeClass("active");
	  });
	});



	$(function() {
		$(".img-btn-block .trigger-grid a").mouseenter(function() {
			var item = $(this).attr("class");
			$(".img-btn-block .grid").find('.' + item).addClass("active");
			$(".img-btn-block .textual-grid").find('.' + item).addClass("active");
	  }).mouseleave(function () {
			$(".img-btn-block .grid-item").removeClass("active");
			$(".img-btn-block .textual-grid .grid-item").removeClass("active");
	  });
	});



	setInterval(function(){
		if ( $(".mega-menu-item").hasClass("mega-toggle-on") ) {
			$(".blackout").addClass("active");
		} else {
			$(".blackout").removeClass("active");
		}
	}, 100);




	$(".faq-block select").change(function() {
	  window.location = $(this).find("option:selected").val();
	});



	$('.nav-btn a').wrapInner('<span></span>')



	$('#vimeo-player').each(function(e){

		var vimeo = document.getElementById(this.id);
		var player = new Vimeo.Player(vimeo);

		// bind events
		var playButton = document.getElementById("play-button");
		playButton.addEventListener("click", function() {
			player.play();
			player.setVolume(1);

			$(this).addClass("playing");
			$(".fader").addClass("playing");
			$(".fader-logo").addClass("playing");
			$(".placeholder").addClass("playing");
			$(".video-text").addClass("playing");
		});

		player.on('play', function(data) {
			$(singular_vimeo).addClass("loaded");
		});

	});



	var swiper1 = new Swiper('.news-swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    speed: 2000,
    // centeredSlides: true,
    autoplay: {
      delay: 7000,
    },
		slidesPerView: 1.2,
		spaceBetween: 10,
  });




	function isScrolledIntoView(elem) {
	    var docViewTop = $(window).scrollTop();
	    var docViewBottom = docViewTop + $(window).height();

	    var elemTop = $(elem).offset().top;
	    var elemBottom = elemTop + $(elem).height();

	    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
	}

	$(window).scroll(function () {
	    $('.animateicon').each(function () {
	        if (isScrolledIntoView(this) === true) {
	            $(this).addClass('visible');
	        }
	    });

	});


	// Script
	var lastScroll = 0;
	$(window).on('scroll',function() {
	    var scroll = $(window).scrollTop();
	    if(lastScroll - scroll > 0) {
	        $("header").addClass("scrolling-up");
	    } else {
	        $("header").removeClass("scrolling-up");
	    }
	    lastScroll = scroll;
	});

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();

		// Change header position
		if(scroll > 50){
			$('header').addClass('scrolling');
		}else{
			$('header').removeClass('scrolling');
		}

		// Change header position
		if(scroll < 350){
			$('header').removeClass('scrolling-up');
		}

	});



	/* Lightbox
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// Open

	$( ".lightbox-button" ).click(function() {
		// stopScroll();
		var lightbox_id = $( this ).prop('id');
		$( "#" + lightbox_id + "-content" ).addClass("active");
		if (lightbox_id == 'lightbox-search'){
			setTimeout(searchFocus, 400);
		}
	});

	// Close

	function close_lightbox() {
		// startScroll();
		$( ".custom-lightbox" ).removeClass("active");
	}

	$( ".close-btn" ).click(function() {
		close_lightbox();
	});

	$(document).on('click', function(event) {
		if ($(event.target).has('.lightbox-content').length) {
			close_lightbox();
		}
	});

	/* Search Lightbox
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// Search Focus on input

	function searchFocus(){
		$("input#s").focus();
	}






	/* To the top
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	$(".back-to-top").click(function(){
      $("html, body").animate({ scrollTop: 0 }, 600);
      return false;
  });



	/* Swipe Menu (Custom Moblie Menu)
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	$('#mobile-menu').mmenu({
    extensions: ["border-full", "pagedim-black", "position-right"],
		navbar:
		{
			title	:	" "
		},
		offCanvas: {
				pageSelector: ".site-wrap"
		},
		scrollBugFix: {
    	fix:true,
    },
		slidingSubmenus: false,
		navbars: [
			 {
					"position": "top",
					"content": [
						 "breadcrumbs"
					]
			 }
		]
  });

	$('.mobile-menu-footer').appendTo('.mm-navbars_bottom');



	$( ".scroll-down" ).click(function() {
		let pageHeight = window.innerHeight;
		window.scroll({top:pageHeight,behavior: 'smooth'});
	});



	/* Sticky Header
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	// var headerHeight = $('.top-nav').outerHeight() + $('header').outerHeight();
	var headerHeight = $('header').outerHeight();

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		headerHeight = $('header').outerHeight() + $('header').outerHeight();

		if(scroll > headerHeight){
			$('header').addClass('Sticky');
			$('body').addClass('menu_is_sticky');
		}else{
			$('header').removeClass('Sticky');
			$('body').removeClass('menu_is_sticky');
		}

		if(scroll > 700){
			$('.back-to-top').addClass('visible');
		}else{
			$('.back-to-top').removeClass('visible');
		}

		if(scroll > 100){
			$('.arrow-container').addClass('invisible');
		} else{
			$('.arrow-container').removeClass('invisible');
		}


	});




	$( ".mburger-mobilemenu" ).click(function() {
		$(".mm-menu").removeClass('mm-menu_opened');
		$("html").removeAttr("class");
		$(".site-wrap").removeAttr("style");
	});

	$( ".mburger-normal" ).click(function() {
		$(".mm-menu").addClass('mm-menu_opened');
		$("html").addClass("mm-wrapper_opened");
		$("html").addClass("mm-wrapper_blocking");
		$("html").addClass("mm-wrapper_background");
		$("html").addClass("mm-wrapper_opening");
	});



	/* Accordion
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	var allPanels1 = $('.accordion-block .item .answer').hide();
	var question1 = $('.accordion-block .item .question');

	$('.accordion-block .item .question a').click(function() {
		allPanels1.slideUp();
		question1.removeClass( "active" );
		$(this).parent().next().slideDown();
		$(this).parent().addClass( "active" );
		return false;
	});

	$(".accordion-block .item .question:first").addClass('active');
	$(".accordion-block .item .answer:first").show();



	/* Document Library
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	var allPanels2 = $('.document-library-block .category .files').hide();
	var trigger2 = $('.document-library-block .category .name');

	$('.document-library-block .category .name a').click(function() {
		allPanels2.slideUp();
		trigger2.removeClass( "active" );
		$(this).parent().next().slideDown();
		$(this).parent().addClass( "active" );
		return false;
	});

	$(".document-library-block .category .name:first").addClass('active');
	$(".document-library-block .category .files:first").show();



	/* Button Block List Mobile
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	var allPanels3 = $('.button-block-list-block .grid.mobile .grid-item .list').hide();
	var trigger3 = $('.button-block-list-block .grid.mobile .grid-item .title');

	$('.button-block-list-block .grid.mobile .grid-item .title a').click(function() {
		allPanels3.slideUp();
		trigger3.removeClass( "active" );
		$(this).parent().next().slideDown();
		$(this).parent().addClass( "active" );
		return false;
	});

	$(".button-block-list-block .grid.mobile .grid-item .title:first").addClass('active');
	$(".button-block-list-block .grid.mobile .grid-item .list:first").show();



})( jQuery );
